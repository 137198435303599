import axios from '@/libs/axios'
import store from '@/store'
import crudActions from '@/libs/storeConstActions'

const suppliersEndpoint = () => `products/${store.state?.inventories?.inventoryId}/suppliers`
const endpoint = () => `inventory/${store.state?.inventories?.inventoryId}/quote`

const Actions = crudActions(endpoint)

const getSuppliers = ({ commit }) => new Promise((resolve, reject) => {
  axios.get(suppliersEndpoint())
    .then(response => {
      commit('LIST', response.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

const updateProcurement = (ctx, data) => axios.put(suppliersEndpoint(), data)

export default {
  ...Actions,
  getSuppliers,
  updateProcurement,
}
