export default {
  GET(state, data) {
    state.quote = { ...data }
  },
  LIST(state, data) {
    state.suppliers = data ?? []
  },
  SET_FORM(state, data) {
    state.quoteForm = { ...data }
  },
  SET_SUPPLIER_FORM(state, data) {
    state.supplierForm = { ...data }
  },
}
