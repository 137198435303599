<template>
  <div>
    <portal to="body-top">
      <h4 class="text-center font-weight-bolder">
        {{ $t(`Request for Quote — ${ product.id ? product.sku: '———' } - ${ product.id ? product.name: '———' }`) }}
      </h4>
    </portal>
    <div class="mt-2">
      <router-view />
    </div>
  </div>
</template>

<script>
import store from '@/store'
import quoteModule from '@/store/main/inventory/quote'
import { onUnmounted } from '@vue/composition-api'
import config from '@/views/main/inventory/inventoryConfig'

export default {
  name: 'Quote',

  data() {
    return {
      routeID: this.$route.params.id,
    }
  },
  computed: {
    product() {
      return this.$store.state[this.MODULE_NAME].product
    },
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME_QUOTE}/SET_FORM`, {
      amount_to_add: [
        {
          ...this.blankLocation,
        },
      ],
      required_by: new Date(),
      suppliers_for_quote: [],
    })
  },
  setup() {
    const MODULE_NAME_QUOTE = 'quote'
    const MODULE_NAME = 'inventories'
    const { blankLocation } = config()

    if (!store.hasModule(MODULE_NAME_QUOTE)) store.registerModule(MODULE_NAME_QUOTE, quoteModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME_QUOTE)) store.unregisterModule(MODULE_NAME_QUOTE)
    })

    return {
      MODULE_NAME,
      blankLocation,
      MODULE_NAME_QUOTE,
    }
  },
}
</script>
